import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import { useQuery } from '@tanstack/react-query';
import { getRoles } from '../api/roles.api';
import { setRoles } from '../store/slices/appSlice';
import { getSites } from '../api/sites.api';
import { setSites } from '../store/slices/companySlice';
import { Spinner } from '@shopify/polaris';
import { logout } from '../store/slices/authSlice';
import '../styles/Animations.css';

const PrivateRoutes = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { data: roles, isLoading } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles
  });

  const {
    data: sites,
    isLoading: areSitesLoading,
    isError: didSitesError
  } = useQuery({
    queryKey: ['sites'],
    queryFn: getSites
  });

  useEffect(() => {
    if (sites && !areSitesLoading && !didSitesError) {
      dispatch(setSites(sites));
    } else if (didSitesError) {
      dispatch(logout());
    }
  }, [sites, areSitesLoading, didSitesError]);

  // set default roles in app slice
  useEffect(() => {
    if (roles && !isLoading) {
      dispatch(setRoles(roles));
    }
  }, [roles, isLoading]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(() => {
    const savedState = localStorage.getItem('isSidebarOpen');
    return savedState ? JSON.parse(savedState) : window.innerWidth >= 641;
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isAnimating, setIsAnimating] = useState(false);

  const toggleSidebar = () => {
    setIsAnimating(true);
    setIsSidebarOpen((prevState) => {
      const newState = !prevState;
      localStorage.setItem('isSidebarOpen', JSON.stringify(newState));
      return newState;
    });
    setTimeout(() => setIsAnimating(false), 300);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    const handleOutsideClick = (event) => {
      if (window.innerWidth < 641) {
        if (isSidebarOpen && !event.target.closest('.header-container')) {
          setIsSidebarOpen(false);
        }
      }
    };
    document.addEventListener('click', handleOutsideClick);
    window.addEventListener('resize', handleResize);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      window.removeEventListener('resize', handleResize);
    };
  }, [isSidebarOpen, windowWidth]);

  return auth.user ? (
    <>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={setIsSidebarOpen} isAnimating={isAnimating} />
      <Header isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} isAnimating={isAnimating} />
      <div
        className={`${isAnimating ? (windowWidth < 640 ? (isSidebarOpen ? 'content-open pl-0' : 'content-closed pl-20') : isSidebarOpen === true ? 'content-open pl-64' : 'content-closed pl-20') : windowWidth < 640 ? (isSidebarOpen ? 'pl-0' : 'pl-20') : isSidebarOpen === true ? 'pl-64' : 'pl-20'}`}>
        {!auth.isSiteLoading ? (
          <Outlet />
        ) : (
          <div className="min-h-[80vh] w-full border grid place-items-center">
            <Spinner size="large" />
          </div>
        )}
      </div>
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;
