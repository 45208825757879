import { configureStore } from "@reduxjs/toolkit"
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import scenarioReducer from "./slices/scenarioSlice"
import companyReducer from "./slices/companySlice"
import eventReducer from "./slices/eventSlice"
import authReducer from "./slices/authSlice"
import appReducer from "./slices/appSlice"

const persistConfig = {
    key: 'root',
    storage,
};

const rootReducer = combineReducers({
    app: appReducer,
    scenario: scenarioReducer,
    company: companyReducer,
    event: eventReducer,
    auth: authReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PERSIST', 'persist/PURGE', 'persist/REGISTER'],
            },
        }),
})

export const persistor = persistStore(store);