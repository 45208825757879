import { constructPhoneNumberWithTimezone } from '../utils';
import api from './index';
const resource = '/users';

export const setPassword = async (payload) => {
  try {
    const response = await api.post(`${resource}/update-password`, payload);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const verifyJwt = async (jwt) => {
  try {
    const response = await api.get(`${resource}/verify-jwt/${jwt}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getAllUsers = async () => {
  try {
    const response = await api.get(`${resource}`);
    if (response.data.success) {
      return response.data.result;
    }
    throw new Error('Unable to fetch users');
  } catch (e) {
    throw new Error(e);
  }
};

export const inviteUser = async (userData) => {
  try {
    const phoneWithTz = constructPhoneNumberWithTimezone(userData.timezone, userData.phone)
    const payload = {...userData, phone: phoneWithTz}
    const response = await api.post(`${resource}/invite-user`, payload);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};

export const reinviteUser = async (username) => {
  try {
    const response = await api.get(`${resource}/reinvite-user/${username}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteUser = async (username) => {
  try {
    const response = await api.delete(`${resource}/${username}`);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const updateUser = async (userData) => {
  try {
    const phoneWithTz = constructPhoneNumberWithTimezone(userData.timezone, userData.phone)
    const payload = {...userData, phone: phoneWithTz}
    const response = await api.post(`${resource}/update-user-info`, payload);
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const resetPassword = async (userData) => {
  try {
    const response = await api.post(`auth/forgot-password`, userData);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};
