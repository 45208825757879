import { Box, Button, Card } from '@shopify/polaris';
import { MobileBackArrowMajor } from '@shopify/polaris-icons';
import { useMutation } from '@tanstack/react-query';
import { notification } from 'antd';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createSpeaker } from '../../api/speakers.api';
import SpeakerForm from '../../components/speakers/SpeakerForm';

const AddSpeaker = () => {
  const [speakerDetails, setSpeakerDetails] = useState({
    speaker_name: '',
    ip_address: '',
    camera_id: null,
    area: null
  });
  /* eslint-disable no-unused-vars */
  const [speakerId, setSpeakerId] = useState(null);
  const [errors, setErrors] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const handleInputChange = (field) => (value) => {
    setSpeakerDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value
    }));

    if (errors[field]) {
      setErrors((prevErrors) => {
        const { [field]: _, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  const validate = () => {
    const newErrors = {};
    if (!speakerDetails.speaker_name.trim()) {
      newErrors.speaker_name = 'Name is required.';
    }
    const ipPattern = /^(([0-9]{1,3}\.){3}[0-9]{1,3}):[0-9]+$/;
    if (!speakerDetails.ip_address.trim()) {
      newErrors.ip_address = 'IP Address is required.';
    } else if (!ipPattern.test(speakerDetails.ip_address.trim())) {
      newErrors.ip_address = 'Invalid IP Address format. Should be in IP:Port format.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const { mutate } = useMutation({
    mutationFn: createSpeaker,
    onSuccess: (response) => {
      api.success({
        message: 'Speaker created successfully',
        placement: 'topRight'
      });
      setSpeakerId(response.id);
      setTimeout(() => {
        navigate('/speakers');
      }, 1000);
    },
    onError: (error) => {
      api.error({
        message: 'Error creating speaker',
        description: error.message,
        placement: 'topRight'
      });
    }
  });

  const handleSaveSpeaker = () => {
    if (!validate()) return;

    const newSpeaker = {
      ...speakerDetails
    };

    mutate(newSpeaker);
  };

  return (
    <>
      {contextHolder}
      <div className="radiousnone main-title">
        <div className="bg-white py-3 px-3 shadow-md border-b">
          <div className="title smallbtn flex items-center gap-3">
            <Link to="/speakers">
              <MobileBackArrowMajor className="w-6" />
            </Link>
            <h3 className="text-black text-lg font-semibold">Add Speaker</h3>
          </div>
        </div>
      </div>
      <div className="px-4 py-4">
        <Box background="bg-surface-secondary" borderRadius="10" border="base" padding="4">
          <div className="max-w-5xl mx-auto py-10">
            <Card padding="0">
              <div className="px-5">
                <SpeakerForm
                  speakerDetails={speakerDetails}
                  errors={errors}
                  handleInputChange={handleInputChange}
                />
                <div className="w-full flex justify-end gap-4 pb-5 border-b">
                  <Button size="large" variant="primary" onClick={handleSaveSpeaker}>
                    Save
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </Box>
      </div>
    </>
  );
};

export default AddSpeaker;
