import { Card, Spinner } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, ArcElement, Title, Tooltip, Legend);

function PieChart({ data, isLoading, name }) {
  const piechartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false // Hide legend
      },
      tooltips: {
        enabled: false // Disable default tooltips
      }
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [PieChartComponent, setPieChartComponent] = useState({
    labels: data?.labels ?? [],
    datasets: [
      {
        data: data?.values ?? [],
        backgroundColor: [
          '#5746FF',
          '#3A67FF',
          '#829BFC',
          '#B8C9FC',
          '#D9E0FB',
          '#684DFF',
          '#4B7AFF',
          '#A1B3FC',
          '#CCD7FB',
          '#ECF1FB',
          '#7157FF',
          '#537CFF',
          '#99A6FC',
          '#D0D6FB',
          '#F3F7FC'
        ]
      }
    ]
  });

  useEffect(() => {
    if (data) {
      setPieChartComponent({
        labels: data?.labels ?? [],
        datasets: [
          {
            data: data?.values ?? [],
            backgroundColor: [
              '#F6F2FF', 
              '#D3C4FF', 
              '#A995FC', 
              '#7965F5', 
              '#4435E8', 
              '#1915B8', 
              '#070C87', 
              '#030B57', 
              '#010726', 
              '#f7fcfd',
              '#e0ecf4',
              '#bfd3e6',
              '#9ebcda',
              '#8c96c6',
              '#8c6bb1',
              '#88419d',
              '#6e016b',
              '#f7fcf0',
              '#e0f3db',
              '#ccebc5',
              '#a8ddb5',
              '#7bccc4',
              '#4eb3d3',
              '#2b8cbe',
              '#08589e'
            ]
          }
        ]
      });
    }
  }, [data]);
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const renderCustomLegend = (chartData) => {
    const itemsToShow = isExpanded ? chartData.labels.length : 5;
    return (
      <div className="custom-legend space-y-5 mx-16">
        {chartData.labels.slice(0, itemsToShow).map((label, index) => (
          <div key={index} className="legend-item">
            <div className="flex items-center justify-between">
              <div className="flex gap-3 items-center">
                <span
                  className="legend-color w-5 h-2.5 rounded-full block"
                  style={{
                    backgroundColor: chartData.datasets[0].backgroundColor[index]
                  }}></span>
                <span className="legend-label">{label}</span>
              </div>
              <span className="legend-count">{chartData.datasets[0].data[index]}</span>
            </div>
          </div>
        ))}
        {chartData.labels.length > 5 && (
          <div className="text-center mt-4">
            <button onClick={toggleExpand} className="text-blue-500">
              {isExpanded ? 'View Less' : 'View More'}
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card>
      {isLoading || !data ? (
        <div className="flex justify-center items-center" style={{ height: '400px' }}>
          <Spinner accessibilityLabel="Loading chart" size="large" />
        </div>
      ) : (
        <div className="">
          <h3 className="font-semibold text-[18px] pb-3 pt-2 pl-2">{name}</h3>
          <div className="flex-wrap lg:flex-nowrap flex items-center justify-between gap-5">
            <div className="chart-container mx-auto py-5 pb-0" style={{ height: '320px' }}>
              <Pie data={PieChartComponent} options={piechartoptions} />
            </div>
            <div className="w-full">{renderCustomLegend(PieChartComponent)}</div>
          </div>
        </div>
      )}
    </Card>
  );
}

export default PieChart;
