import React, { useState } from "react";
import { Line, Group, Circle} from "react-konva";

export const getAvaragePoint = (points) => {
    let totalX = 0;
    let totalY = 0;
    for (let i = 0; i < points.length; i += 2) {
      totalX += points[i];
      totalY += points[i + 1];
    }
    return {
      x: totalX / (points.length / 2),
      y: totalY / (points.length / 2),
    };
  };
  export const getDistance = (node1, node2) => {
    let diffX = Math.abs(node1[0] - node2[0]);
    let diffY = Math.abs(node1[1] - node2[1]);
    const distaneInPixel = Math.sqrt(diffX * diffX + diffY * diffY);
    return Number.parseFloat(distaneInPixel).toFixed(2);
  };
  export const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos) => {
    let x = pos.x;
    let y = pos.y;
    if (pos.x + vertexRadius > stageWidth) x = stageWidth;
    if (pos.x - vertexRadius < 0) x = 0;
    if (pos.y + vertexRadius > stageHeight) y = stageHeight;
    if (pos.y - vertexRadius < 0) y = 0;
    return { x, y };
  };
  export const minMax = (points) => {
    return points.reduce((acc, val) => {
      acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
      acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
      return acc;
    }, []);
  };

/**
 *
 * @param {minMaxX} props
 * minMaxX[0]=>minX
 * minMaxX[1]=>maxX
 *
 */
const PolygonAnnotation = (props) => {
  const {
    points,
    flattenedPoints,
    isFinished,
    handlePointDragMove,
    handleGroupDragEnd,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handleGroupMouseOver,
    handleGroupMouseOut,
    stage 
  } = props;
  const vertexRadius = 6;

  const [minMaxX, setMinMaxX] = useState([0, 0]); //min and max in x axis
  const [minMaxY, setMinMaxY] = useState([0, 0]); //min and max in y axis
  const handleGroupDragStart = () => {
    let arrX = points.map((p) => p[0]);
    let arrY = points.map((p) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
  };
  const groupDragBound = (pos) => {
    let { x, y } = pos;
    const sw = stage.width();
    const sh = stage.height();
    if (minMaxY[0] + y < 0) y = -1 * minMaxY[0];
    if (minMaxX[0] + x < 0) x = -1 * minMaxX[0];
    if (minMaxY[1] + y > sh) y = sh - minMaxY[1];
    if (minMaxX[1] + x > sw) x = sw - minMaxX[1];
    return { x, y };
  };
  return (
    <Group
      name="polygon"
      // draggable={isFinished}
      onDragStart={handleGroupDragStart}
      onDragEnd={handleGroupDragEnd}
      dragBoundFunc={groupDragBound}
      onMouseOver={handleGroupMouseOver}
      onMouseOut={handleGroupMouseOut}
    >
      <Line
        points={flattenedPoints}
        stroke="#4bef10"
        strokeWidth={3}
        closed={isFinished}
        fill="rgb(75,239,16,0.5)"
      />
      {points.map((point, index) => {
        const x = point[0] - vertexRadius / 2;
        const y = point[1] - vertexRadius / 2;
        const startPointAttr =
          index === 0
            ? {
                hitStrokeWidth: 12,
                onMouseOver: handleMouseOverStartPoint,
                onMouseOut: handleMouseOutStartPoint,
              }
            : null;
        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={vertexRadius}
            fill="#ffd11b"
            stroke="#4bef10"
            strokeWidth={2}
            draggable
            onDragMove={handlePointDragMove}
            dragBoundFunc={(pos) =>
              dragBoundFunc(stage.width(), stage.height(), vertexRadius, pos)
            }
            {...startPointAttr}
          />
          
        
        );
      })}
    </Group>
  );
};

export default PolygonAnnotation;
