import React, { useMemo, useRef, useState, useEffect } from 'react';
import PolygonAnnotation from './PolygonAnnotation';
import { Stage, Layer, Image } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { setScenarioFormData } from '../../store/slices/scenarioSlice';

const Canvas = ({
  camera,
  imageSrc,
  polygons,
  setPolygons,
  isPolygonCompleteArr,
  setIsPolygonCompleteArr,
  currentPolygonIdx,
  setDisableSubmit
}) => {
  const [image, setImage] = useState();
  const imageRef = useRef(null);
  // const dataRef = useRef(null);
  const [size, setSize] = useState({ width: 650, height: 500 });
  const [position, setPosition] = useState([0, 0]);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [stage, setStage] = useState();
  const lastKnownSizeRef = useRef(size);
  const dispatch = useDispatch();
  const { scenarioFormData: formData } = useSelector((state) => state.scenario);
  const setCameraDimensions = ({ height, width }) => {
    dispatch(
      setScenarioFormData({
        ...formData,
        imageDimensionsPerCamera: {
          ...formData.imageDimensionsPerCamera,
          [camera.name]: { height: Number(height).toFixed(2), width: Number(width).toFixed(2) }
        }
      })
    );
  };

  const adjustSizeBasedOnViewport = (width, height) => {
    // Adjust size based on the viewport, maintaining aspect ratio
    const maxWidth = window.innerWidth * 0.8; // 80% of viewport width
    const maxHeight = window.innerHeight * 0.8; // 80% of viewport height
    const aspectRatio = width / height;

    let newWidth = width;
    let newHeight = height;

    if (width > maxWidth) {
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    }
    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = maxHeight * aspectRatio;
    }

    return { width: newWidth, height: newHeight };
  };

  // For group drag cursor change when mouse enters the group
  const handleGroupMouseOver = (e, idx) => {
    if (!isPolygonCompleteArr[idx]) return;
    // e.target.getStage().container().style.cursor = 'move';
    setStage(e.target.getStage());
  };

  // for group drag cursor change when mouse leaves the group
  const handleGroupMouseOut = (e) => {
    e.target.getStage().container().style.cursor = 'default';
  };

  useEffect(() => {
    const videoElement = new window.Image();
    // videoElement.src = `data:image/jpeg;base64,${imageSrc}`;
    videoElement.src = `${imageSrc}`;

    const onload = () => {
      const adjustedSize = adjustSizeBasedOnViewport(videoElement.width, videoElement.height);
      setSize(adjustedSize);
      setImage(videoElement);
      setCameraDimensions(adjustedSize);
      imageRef.current = videoElement;
      lastKnownSizeRef.current = adjustedSize;
      if (
        !formData.actualImageDimensionsPerCamera ||
        !(formData.cameraName in formData.actualImageDimensionsPerCamera)
      )
        return;
      const originalWidth = formData.actualImageDimensionsPerCamera[formData.cameraName].width;
      const originalHeight = formData.actualImageDimensionsPerCamera[formData.cameraName].height;

      // Calculate scale factors based on the original dimensions
      const scaleX = adjustedSize.width / originalWidth;
      const scaleY = adjustedSize.height / originalHeight;

      // Scale the polygons
      const scaledPolygons = polygons.map((polygon) =>
        polygon.map(([x, y]) => [x * scaleX, y * scaleY])
      );

      // Update the polygons state with scaled coordinates
      setPolygons(scaledPolygons);
    };

    videoElement.addEventListener('load', onload);

    // Cleanup
    return () => videoElement.removeEventListener('load', onload);
  }, [imageSrc]);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current && lastKnownSizeRef.current) {
        const originalWidth = lastKnownSizeRef.current.width;
        const originalHeight = lastKnownSizeRef.current.height;

        // Adjust the size based on the viewport
        const adjustedSize = adjustSizeBasedOnViewport(
          imageRef.current.width,
          imageRef.current.height
        );
        setSize(adjustedSize);

        // Calculate scale factors based on the last known size
        const scaleX = adjustedSize.width / originalWidth;
        const scaleY = adjustedSize.height / originalHeight;

        // Scale the polygons
        const scaledPolygons = polygons.map((polygon) =>
          polygon.map(([x, y]) => [x * scaleX, y * scaleY])
        );

        // Update the polygons state with scaled coordinates
        setPolygons(scaledPolygons);

        // Update the last known size
        lastKnownSizeRef.current = adjustedSize;
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [polygons]); 

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };
  //drawing begins when mousedown event fires.
  // start drawing
  const handleMouseDown = (e, idx) => {
    if (isPolygonCompleteArr[idx]) return;
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isMouseOverPoint && polygons[idx].length >= 3) {
      setIsPolygonCompleteArr([
        ...isPolygonCompleteArr.slice(0, idx),
        true,
        ...isPolygonCompleteArr.slice(idx + 1)
      ]);
      setDisableSubmit(false)
    } else {
      setPolygons([
        ...polygons.slice(0, idx),
        [...polygons[idx], mousePos],
        ...polygons.slice(idx + 1)
      ]);
      setDisableSubmit(true)

    }
  };

  // TO ENABLE NEW POLYGON WITHOUT DRAWING
  // useEffect(() => {
  //   if(isPolygonCompleteArr[currentPolygonIdx]){
  //     setPolygons([...polygons, []]);
  //     setIsPolygonCompleteArr([...isPolygonCompleteArr, false]);
  //     setCurrentPolygonIdx(polygons.length);
  //     // setCurrentPolygonIdx((prev) => prev + 1);
  //   }

  // },[isPolygonCompleteArr, currentPolygonIdx])
  // to handle mouse move event (for drawing)
  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    setPosition(mousePos);
  };
  // to close polygon (to finish drawing)
  const handleMouseOverStartPoint = (e, idx) => {
    if (isPolygonCompleteArr[idx] || polygons[idx].length < 3) return;
    e.target.scale({ x: 3, y: 3 });
    setMouseOverPoint(true);
  };

  // to handle mouse out event (for start point)
  const handleMouseOutStartPoint = (e) => {
    e.target.scale({ x: 1, y: 1 });
    setMouseOverPoint(false);
  };

  // to handle point drag move event
  const handlePointDragMove = (e, idx) => {
    const stage = e.target.getStage();
    const index = e.target.index - 1;
    const pos = [e.target._lastPos.x, e.target._lastPos.y];
    if (pos[0] < 0) pos[0] = 0;
    if (pos[1] < 0) pos[1] = 0;
    if (pos[0] > stage.width()) pos[0] = stage.width();
    if (pos[1] > stage.height()) pos[1] = stage.height();
    setPolygons([
      ...polygons.slice(0, idx),
      [...polygons[idx].slice(0, index), pos, ...polygons[idx].slice(index + 1)],
      ...polygons.slice(idx + 1)
    ]);
  };

  const flattenedPolygons = useMemo(() => {
    return polygons.map((polygon, idx) => {
      return polygon
        .concat(isPolygonCompleteArr[idx] ? [] : position)
        .reduce((a, b) => a.concat(b), []);
    });
  }, [polygons, position, isPolygonCompleteArr]);

  const handleGroupDragEnd = (e, idx) => {
    if (e.target.name() === 'polygon') {
      const newPolygons = [];
      for (let i = 0; i < polygons.length; i++) {
        if (i !== idx) {
          newPolygons.push(polygons[i]);
        } else {
          let newPoints = [];
          let oldPoints = [...polygons[i]];
          oldPoints.map((point) =>
            newPoints.push([point[0] + e.target.x(), point[1] + e.target.y()])
          );
          newPolygons.push(newPoints);
        }
      }
      setPolygons(newPolygons);
    }
  };

  return (
    <div className="flex flex-row gap-2 justify-center">
      <div>
        <Stage
          width={size.width || 650}
          height={size.height || 500}
          onMouseMove={handleMouseMove}
          onMouseDown={(e) => handleMouseDown(e, currentPolygonIdx)}>
          <Layer>
            <Image
              ref={imageRef}
              image={image}
              x={0}
              y={0}
              width={size.width}
              height={size.height}
            />

            {polygons.map((polygon, idx) => {
              return (
                <PolygonAnnotation
                  key={idx}
                  points={polygons[idx]}
                  stage={stage}
                  handleGroupMouseOut={handleGroupMouseOut}
                  handleGroupMouseOver={(e) => handleGroupMouseOver(e, idx)}
                  flattenedPoints={flattenedPolygons[idx]}
                  handlePointDragMove={(e) => handlePointDragMove(e, idx)}
                  handleGroupDragEnd={(e) => handleGroupDragEnd(e, idx)}
                  handleMouseOverStartPoint={(e) => handleMouseOverStartPoint(e, idx)}
                  handleMouseOutStartPoint={handleMouseOutStartPoint}
                  isFinished={isPolygonCompleteArr[idx]}
                />
              );
            })}
          </Layer>
        </Stage>
      </div>
      {/* <div
        ref={dataRef}
        style={{
          width: 375,
          height: 302,
          boxShadow: ".5px .5px 5px .4em rgba(0,0,0,.1)",
          marginTop: 20,
        }}
      >
        <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(polygons)}</pre>
      </div> */}
    </div>
  );
};

export default Canvas;
