// import profile from "../assets/profile.png"
import Logowhite from '../assets/logo-white.svg';
import Logowhitesmall from '../assets/logowhite.svg';
// import HomeIco from "../assets/home.svg"
import CameraIco from '../assets/camera.svg';
import AiIco from '../assets/ai-chip.svg';
import SpeakerIco from '../assets/mic.svg';
import EventIco from '../assets/event.svg';
import DashboardIco from '../assets/dashboard.svg';
import campaignIco from '../assets/campaign.svg';
import { useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from './Avatar';
import { Select } from '@shopify/polaris';
import { setSite, setSiteLoader, setToReload } from '../store/slices/authSlice';
import { setChosenEventIdx } from '../store/slices/eventSlice';
import '../styles/Animations.css';

const getSitesForSelect = (sitesAvailable) => {
  if (!sitesAvailable || sitesAvailable.length === 0) return [];
  return sitesAvailable.map((site) => ({ value: site.name, label: site.name }));
};

const Sidebar = (props) => {
  const { isOpen, toggleSidebar, isAnimating } = props;
  const { user: userObj, chosenSite, toReload } = useSelector((state) => state.auth);
  const { sitesAvailable } = useSelector((state) => state.company);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 641) {
        toggleSidebar(false);
      } else toggleSidebar(true);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onSiteChange = (selected) => {
    const site = sitesAvailable.find((site) => site.name === selected);
    if (site) {
      try {
        dispatch(setSiteLoader(true));
        dispatch(
          setSite({
            name: site.name,
            id: site.id
          })
        );
        // eslint-disable-next-line no-empty
      } catch (e) {
      } finally {
        setTimeout(() => {
          dispatch(setSiteLoader(false));
          dispatch(setToReload(true));
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (chosenSite && chosenSite.id && toReload) {
      dispatch(setToReload(false));
      navigateTo('/events');
    }
  }, [toReload]);

  const resetEventIdx = () => {
    return dispatch(setChosenEventIdx(null));
  };

  return (
    <div
      style={{ width: isOpen ? '16rem' : '5rem' }}
      className={`header-container fixed top-0 left-0 z-[1000] h-screen transition-transform ${isAnimating ? (isOpen ? 'sidebar-open' : 'sidebar-closed') : ''} ${isOpen ? 'block' : 'flex flex-col justify-center'}`}>
      <aside id="default-sidebar" className="h-screen" aria-label="Sidebar">
        <div
          className={`h-full px-3 py-4 overflow-y-auto bg-[#1A1A1A] flex flex-col justify-between ${isOpen ? '' : 'items-center'}`}>
          <div>
            <div className="flex justify-center">
              <Link to="/" className={'logo'}>
                <img src={isOpen ? Logowhite : Logowhitesmall} alt="logo" />
              </Link>
            </div>
            <div
              className={`profile mt-10 mx-auto text-center mb-5 flex flex-col items-center ${isOpen ? '' : 'hidden'}`}>
              <Avatar name={userObj?.user?.name ?? 'Anonymous'} />
              <h2 className="text-white text-center font-semibold text-xl pt-3">
                {userObj?.user?.name ?? 'Anonymous'}
              </h2>
              <p className="text-[#616161] text-base">
                {userObj.roleInfo?.name
                  .split('-')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                  .join(' ') ?? 'Unknown Role'}
              </p>
            </div>
            <ul
              className={`space-y-2 font-medium sidebar_menu ${isOpen ? '' : 'flex flex-col items-center py-16'}`}>
              <li className="rounded-md">
                <NavLink
                  to="/events"
                  className="flex items-center p-3 text-[#F7F7F7] rounded-lg group"
                  onClick={resetEventIdx}>
                  <img src={EventIco} alt="events" />
                  <span className={`ms-3 text-[#F7F7F7] text-base ${isOpen ? '' : 'hidden'}`}>
                    Events
                  </span>
                </NavLink>
              </li>
              <li className="rounded-md">
                <NavLink
                  to="/cameras"
                  className="flex items-center p-3 text-[#F7F7F7] rounded-lg group">
                  <img src={CameraIco} alt="cameras" />
                  <span className={`ms-3 text-[#F7F7F7] text-base ${isOpen ? '' : 'hidden'}`}>
                    Cameras
                  </span>
                </NavLink>
              </li>
              <li className="rounded-md">
                <NavLink
                  to="/scenarios"
                  className="flex items-center p-3 text-[#F7F7F7] rounded-lg group">
                  <img src={AiIco} alt="ai scenarios" />
                  <span className={`ms-3 text-[#F7F7F7] text-base ${isOpen ? '' : 'hidden'}`}>
                    AI Scenarios
                  </span>
                </NavLink>
              </li>              
              <li className="rounded-md">
                <NavLink
                  to="/campaigns"
                  className="flex items-center p-3 text-[#F7F7F7] rounded-lg group">
                  <img src={campaignIco} alt="Campaigns" />
                  <span className={`ms-3 text-[#F7F7F7] text-base ${isOpen ? '' : 'hidden'}`}>
                    Campaign
                  </span>
                </NavLink>
              </li>
              <li className="rounded-md">
                <NavLink
                  to="/speakers"
                  className="flex items-center p-3 text-[#F7F7F7] rounded-lg group">
                  <img src={SpeakerIco} alt="Speakers" />
                  <span className={`ms-3 text-[#F7F7F7] text-base ${isOpen ? '' : 'hidden'}`}>
                    Speakers
                  </span>
                </NavLink>
              </li>
              <li className="rounded-md">
                <NavLink
                  to="/dashboard"
                  className="flex items-center p-3 text-[#F7F7F7] rounded-lg group">
                  <img src={DashboardIco} alt="dashboard" />
                  <span className={`ms-3 text-[#F7F7F7] text-base ${isOpen ? '' : 'hidden'}`}>
                    Dashboard
                  </span>
                </NavLink>
              </li>
            </ul>
            <hr className={`mt-5 opacity-40 ${isOpen ? '' : 'hidden'}`} />
          </div>
          {(userObj.role_id <= 2 || userObj.role_id === 6) && (
            <div className={`${isOpen ? '' : 'hidden'}`}>
              <Select
                options={getSitesForSelect(sitesAvailable)}
                onChange={onSiteChange}
                value={chosenSite?.name || 'Default'}
              />
            </div>
          )}
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
