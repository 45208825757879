/* eslint-disable no-unreachable */
import { convertOptionsToConsumableFormat } from '../utils';
import api from './index';

const resource = '/notification_groups';
const userResource = '/notification-user-groups';
const userResourceSnakeCased = '/notification_user_groups';

export const createNewNotificationGroupWithUsers = async ({ groupName, emailList, users }) => {
  try {
    const usersToBeAdded = users.filter((user) => emailList.includes(user.email));
    if (usersToBeAdded.length === 0 || usersToBeAdded.length !== emailList.length) {
      throw new Error('Please enter valid user emails');
    }

    // create a new notification group
    const response = await api.post(`${resource}`, { name: groupName });
    if (!response.data) {
      throw new Error('unable to create notification group');
    }

    // add all users to notification group
    const notificationGroupId = response.data.id;
    const payloads = usersToBeAdded.map((user) => ({
      user_id: user.id,
      notification_group_id: notificationGroupId
    }));

    await Promise.all(
      payloads.map(async (payload) => {
        await api.post(`${userResourceSnakeCased}`, payload);
      })
    );

    return response;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};

export const getNotificationUserGroupsForNotificationGroup = async (notificationGroupId) => {
  try {
    if (!notificationGroupId) return [];
    const response = await api.get(`${userResource}/${notificationGroupId}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSimpleNotificationGroups = async () => {
  try {
    const response = await api.get(`${resource}`);
    if (!response.data) {
      console.log({ error: 'unable to fetch notification groups' });
      return {};
    }
    return response.data;
  } catch (e) {
    throw new Error(e);
  }
};

export const getNotificationGroups = async () => {
  try {
    const response = await api.get(`${resource}`);
    if (!response.data) {
      console.log({ error: 'unable to fetch notification groups' });
      return {};
    }
    return convertOptionsToConsumableFormat(response.data);
  } catch (e) {
    throw new Error(e);
  }
};

export const createNotificationGroup = async (name) => {
  try {
    const response = await api.post(`${resource}`, { name });
    if (!response.data) {
      console.log({ error: 'unable to create notification groups' });
      return {};
    }
    const allNotificationGroups = await getNotificationGroups();
    return allNotificationGroups;
  } catch (e) {
    throw new Error(e);
  }
};

export const removeUserFromNotificationGroup = async (notificationGroupId, userId) => {
  return await api.delete(`${userResourceSnakeCased}/${notificationGroupId}/${userId}`);
};

export const addUserToNotificationGroup = async (notificationGroupId, userId) => {
  return await api.post(`${userResourceSnakeCased}`, {
    user_id: userId,
    notification_group_id: notificationGroupId
  });
};

export const editNotificationUsersList = async ({ notificationId, emailList, usersList }) => {
  try {
    const users = await getNotificationUserGroupsForNotificationGroup(notificationId);
    const usersToRemove = users
      .filter((user) => !emailList.includes(user.user.email))
      .map((user) => user.user.id);

    const usersToAdd = usersList
      .filter((user) => {
        if (
          emailList.includes(user.email) &&
          !users.map((userObj) => userObj.user.email).includes(user.email)
        ) {
          return true;
        }
        return false;
      })
      .map((user) => user.id);

    await Promise.all(
      usersToRemove.map(async (userId) => {
        return await removeUserFromNotificationGroup(notificationId, userId);
      })
    );

    await Promise.all(
      usersToAdd.map(async (userId) => {
        await addUserToNotificationGroup(notificationId, userId);
      })
    );

    return 'DONE';
  } catch (e) {
    throw new Error(e);
  }
};
