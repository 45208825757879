/* eslint-disable */
import BarGraph from './BarChart';
import PieChart from '../../components/charts/Pie';
import Severity from './Severity';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useEffect, useState, useCallback } from 'react';
import {
  getEventItemsBySeverity,
  getEventsByArea,
  getEventsByCamera,
  getEventsBySeverity,
  getEventsOverviewApi,
  getFiltersApi,
  getLineChartApi,
  getOverallStatsApi
} from '../../api/graphs.api';
import { Button, DatePicker, Icon, Select, Tooltip } from '@shopify/polaris';
import LineChart from '../../components/charts/LineChart';
import { downloadReport } from '../../api/reports.api';
import { Modal, notification } from 'antd';
import { Icons } from '../../Icons';
import { format } from 'date-fns';
import { CalendarMinor } from '@shopify/polaris-icons';
import { useSelector } from 'react-redux';
import Sitemap from './Sitemap';

export const PAGE_SIZE = 6;

/** TIGHTLY COUPLED COMPONENT */
const StatCard = ({ title, trend, trendNumber, value, inverseTrendBenefit = false }) => {
  if (title === 'Time Since Last Incident')
    return (
      <div className="bg-white border shadow-md flex flex-col justify-between  rounded-lg py-4 pl-4 pr-8">
        <h6 className="font-semibold">{title}</h6>
        <div className="flex items-center justify-between">
          <div>
            {title === 'Time Since Last Incident' && <span className="text-3xl">{value}</span>}
            <div className="min-h-[23px]"></div>
          </div>
        </div>
      </div>
    );
  return (
    <div className="bg-white border shadow-md flex flex-col justify-between rounded-lg pt-4 pl-4 pr-8">
      <h6 className="font-semibold">{title}</h6>
      <div className="flex items-center justify-between">
        <div>
          <span className="text-3xl">{value}</span>

          {trend === 'POSITIVE' ? (
            <div className="flex">
              <span className={`text-[${!inverseTrendBenefit ? '#45A637' : '#E51C00'}] mr-2`}>
                +{Number(trendNumber).toFixed(2)}%
              </span>
              <Icons.upwardArrow
                className="h-4"
                fill={!inverseTrendBenefit ? '#45A637' : '#E51C00'}
              />
            </div>
          ) : trend === 'NEGATIVE' ? (
            <div className="flex">
              <span className={`text-[${!inverseTrendBenefit ? '#E51C01' : '#45A637'}] mr-2`}>
                -{Number(trendNumber).toFixed(2)}%
              </span>
              <Icons.downwardArrow
                className="h-4"
                fill={!inverseTrendBenefit ? '#E51C00' : '#45A637'}
              />
            </div>
          ) : null}
        </div>
        {trend === 'POSITIVE' ? (
          <div>
            <Icons.upwardLineChart
              className="w-32 h-32"
              stroke={`${!inverseTrendBenefit ? '#04CE00' : '#FF718B'} `}
            />
          </div>
        ) : trend === 'NEGATIVE' ? (
          <div>
            <Icons.downwardLineChart
              className="w-32 h-32"
              stroke={`${!inverseTrendBenefit ? '#FF718B' : '#04CE00'} `}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const formatTimeSince = (dateString) => {
  if (!dateString) {
    return '0d 0h 0m 0s';
  }
  const now = new Date();
  const date = new Date(dateString);
  const diff = now - date;

  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};
const formatTimeFromSeconds = (milliseconds) => {
  let seconds = milliseconds / 1000;
  if (!seconds || seconds < 0) {
    return '0h 0m';
  }

  const hours = Math.floor(seconds / (60 * 60));
  const minutes = Math.floor((seconds / 60) % 60);

  return `${hours}h ${minutes}m`;
};

const Dashboard = () => {
  const { chosenSite } = useSelector((state) => state.auth);
  const { sitesAvailable } = useSelector((state) => state.company);
  const [api, contextHolder] = notification.useNotification();
  const [incidentLogPageNumber, setIncidentLogPageNumber] = useState(1);
  const [severityTypeFilter, setSeverityTypeFilter] = useState(null);

  const [isDateModalOpen, setIsDateModalOpen] = useState(false);

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth() - 1,
    year: new Date().getFullYear()
  });
  const [selectedDates, setSelectedDates] = useState({
    start: (() => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date;
    })(),
    end: new Date()
  });

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const handleOk = () => {
    refetchStats();
    refetchBarChart();
    refetchEventsByArea();
    refetchEventsByCamera();
    refetchEventsBySeverity();
    refetchEventItemsBySeverity();
    refetchLineChartData();
    setIsDateModalOpen(false);
  };

  const handleCancel = () => {
    setIsDateModalOpen(false);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    scenario: '',
    timeRange: '',
    camera: '',
    area: ''
  });

  const [timeSinceLastIncident, setTimeSinceLastIncident] = useState(
    formatTimeSince(new Date().toISOString())
  );

  const [timeBetweenIncidents, setTimeBetweenIncidents] = useState(formatTimeFromSeconds(0));

  const {
    data: filters,
    isLoading: areFiltersLoading,
    isError: isFilterError,
    error: filterError
  } = useQuery({
    queryKey: ['dashboard-filters'],
    queryFn: () => getFiltersApi()
  });

  useEffect(() => {
    if (filters) {
      setSelectedFilters({
        scenario: filters.scenarios[0].value,
        timeRange: filters.timeRange[0].value,
        camera: filters.cameras[0].value,
        area: filters.areas[0].value
      });
    }
  }, [filters]);

  // reset page number to 1 when filters change
  useEffect(() => {
    setIncidentLogPageNumber(1);
  }, [selectedFilters, severityTypeFilter]);

  const { data: statData, refetch: refetchStats } = useQuery({
    queryKey: ['dashboard-data', selectedFilters],
    queryFn: () =>
      getOverallStatsApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: new Date(selectedDates.start).toISOString(),
        endDate: new Date(selectedDates.end).toISOString(),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE'
      })
  });

  useEffect(() => {
    if (statData) {
      const intervalId = setInterval(() => {
        setTimeSinceLastIncident(formatTimeSince(statData.timeSinceLastIncident.value));
      }, 1000);
      if (statData.averageTimeBetweenEvents) {
        setTimeBetweenIncidents(formatTimeFromSeconds(statData.averageTimeBetweenEvents.value));
      }
      return () => clearInterval(intervalId);
    }
  }, [statData]);

  const {
    data: barChartData,
    isLoading: isBarChartLoading,
    refetch: refetchBarChart
  } = useQuery({
    queryKey: ['events-overview', selectedFilters],
    queryFn: () =>
      getEventsOverviewApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: new Date(selectedDates.start).toISOString(),
        endDate: new Date(selectedDates.end).toISOString(),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE'
      })
  });

  const {
    data: eventsDataByArea,
    isLoading: areEventsByAreaLoading,
    refetch: refetchEventsByArea
  } = useQuery({
    queryKey: ['events-by-area', selectedFilters],
    queryFn: () =>
      getEventsByArea({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: new Date(selectedDates.start).toISOString(),
        endDate: new Date(selectedDates.end).toISOString(),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE'
      })
  });

  const {
    data: eventsDataByCamera,
    isLoading: areEventsByCameraLoading,
    refetch: refetchEventsByCamera
  } = useQuery({
    queryKey: ['events-by-camera', selectedFilters],
    queryFn: () =>
      getEventsByCamera({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: new Date(selectedDates.start).toISOString(),
        endDate: new Date(selectedDates.end).toISOString(),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE'
      })
  });

  const {
    data: eventsBySeverity,
    isLoading: areEventsBySeverityLoading,
    refetch: refetchEventsBySeverity
  } = useQuery({
    queryKey: ['severity', selectedFilters],
    queryFn: () =>
      getEventsBySeverity({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: new Date(selectedDates.start).toISOString(),
        endDate: new Date(selectedDates.end).toISOString(),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE'
      })
  });

  const {
    data: eventsBySeverityItems,
    isLoading: areEventItemsBySeverityLoading,
    isError: didEventItemsBySeverityError,
    refetch: refetchEventItemsBySeverity
  } = useQuery({
    queryKey: ['severity-items', selectedFilters, incidentLogPageNumber, severityTypeFilter],
    queryFn: () =>
      getEventItemsBySeverity({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        pageSize: PAGE_SIZE,
        page: incidentLogPageNumber,
        type: severityTypeFilter,
        startDate: new Date(selectedDates.start).toISOString(),
        endDate: new Date(selectedDates.end).toISOString(),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE'
      })
  });

  const {
    data: lineChartData,
    isLoading: isLineChartDataLoading,
    refetch: refetchLineChartData
  } = useQuery({
    queryKey: ['line-chart', selectedFilters],
    queryFn: () =>
      getLineChartApi({
        scenario: selectedFilters.scenario,
        timeRange: selectedFilters.timeRange,
        camera: selectedFilters.camera,
        area: selectedFilters.area,
        startDate: new Date(selectedDates.start).toISOString(),
        endDate: new Date(selectedDates.end).toISOString(),
        customDate: selectedFilters.timeRange === 'CUSTOM_DATE_RANGE'
      })
  });

  const { mutate: generateReport } = useMutation({
    mutationFn: downloadReport,
    onSuccess: () => {
      api.success({
        message: 'Report downloaded successfully',
        duration: 2,
        placement: 'bottomRight'
      });
    },
    onError: (err) => {
      api.error({
        message: 'Selected filters have no data to generate a report.',
        duration: 2,
        placement: 'bottomRight'
      });
    }
  });

  if (isFilterError) {
    return <div>{filterError.message}</div>;
  }

  return (
    <div>
      {contextHolder}
      <Modal
        key={selectedFilters.timeRange}
        width={800}
        title="Choose Date Range"
        open={isDateModalOpen}
        footer={[
          <span className="mr-1">
            <Button variant="secondary" className="mx-2" onClick={handleCancel}>
              Cancel
            </Button>
          </span>,
          <span className="ml-1">
            <Button onClick={handleOk} variant="primary">
              Submit
            </Button>
          </span>
        ]}
        onOk={handleOk}
        onCancel={handleCancel}>
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
          disableDatesAfter={new Date()}
        />
      </Modal>
      <div className="mx-2 mt-2 grid grid-cols-1 md:grid-cols-5 gap-2">
        <Select
          label="Scenarios"
          disabled={areFiltersLoading}
          options={filters?.scenarios}
          value={selectedFilters.scenario}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, scenario: value });
          }}
        />
        <div className="flex items-center justify-between w-full">
          <span className="grow">
            <Select
              label="Time Period"
              disabled={areFiltersLoading}
              options={filters?.timeRange}
              value={selectedFilters.timeRange}
              onChange={(value) => {
                if (value === 'CUSTOM_DATE_RANGE') {
                  // open date picker
                  setIsDateModalOpen(true);
                }
                setSelectedFilters({ ...selectedFilters, timeRange: value });
              }}
            />
          </span>
          <span className="ml-2">
            <Tooltip dismissOnMouseOut content="Select Custom Date Range">
              <Button
                variant="primary"
                onClick={() => {
                  setIsDateModalOpen(true);
                  setSelectedFilters({ ...selectedFilters, timeRange: 'CUSTOM_DATE_RANGE' });
                }}>
                <Icon source={CalendarMinor} />
              </Button>
            </Tooltip>
          </span>
        </div>
        <Select
          label="Cameras"
          disabled={areFiltersLoading}
          options={filters?.cameras}
          value={selectedFilters.camera}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, camera: value });
          }}
        />
        <Select
          label="Areas"
          disabled={areFiltersLoading}
          options={filters?.areas}
          value={selectedFilters.area}
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, area: value });
          }}
        />
        <style>
          {`
          .Polaris-Button {
              max-height: 20px !important;
              margin-top: 1.5rem;
            }
          }
            `}
        </style>
        <Button
          onClick={() => {
            if (selectedFilters.timeRange === 'CUSTOM_DATE_RANGE') {
              generateReport({
                scenarioType: selectedFilters.scenario,
                timePeriod: selectedFilters.timeRange,
                cameraName: selectedFilters.camera === 'All' ? null : selectedFilters.camera,
                area: selectedFilters.area === 'All' ? null : selectedFilters.area,
                startDate: new Date(selectedDates.start).toISOString(),
                endDate: new Date(selectedDates.end).toISOString()
              });
            } else {
              generateReport({
                scenarioType: selectedFilters.scenario,
                timePeriod: selectedFilters.timeRange,
                cameraName: selectedFilters.camera === 'All' ? null : selectedFilters.camera,
                area: selectedFilters.area === 'All' ? null : selectedFilters.area
              });
            }
          }}
          variant="primary">
          <div className="flex generate-button">
            <span className="mr-2">Generate Report</span>
          </div>
        </Button>
      </div>
      <div className="m-2">
        {selectedFilters.timeRange === 'CUSTOM_DATE_RANGE' && (
          <p className="text-sm mb-2">
            {`Selected Date Range: ${format(new Date(selectedDates.start), 'dd MMMM, yyyy')} to ${format(new Date(selectedDates.end), 'dd MMMM, yyyy')}`}
          </p>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 mb-4">
          <StatCard
            title={'Time Since Last Incident'}
            trend={'NEUTRAL'}
            trendNumber="0"
            value={timeSinceLastIncident}
          />
          <StatCard
            title={'Time Between Incidents'}
            trend={statData?.averageTimeBetweenEvents?.trend ?? 'POSITIVE'}
            value={timeBetweenIncidents}
            trendNumber={statData?.averageTimeBetweenEvents?.trendValue ?? 0}
          />
          <StatCard
            title="Total Incidents"
            trend={statData?.totalIncidents?.trend ?? 'POSITIVE'}
            value={statData?.totalIncidents?.value ?? 0}
            trendNumber={statData?.totalIncidents?.trendValue ?? 0}
            inverseTrendBenefit={true}
          />
          <StatCard
            title="Reviewed Incidents"
            trend={statData?.reviewedIncidents?.trend ?? 'POSITIVE'}
            value={
              `${statData?.reviewedIncidents?.value ?? 0} (${(statData?.reviewedIncidentsPercentage?.value ?? 0).toFixed(2)}%)` ??
              '0 (0%)'
            }
            trendNumber={statData?.reviewedIncidents?.trendValue ?? 0}
          />
        </div>
        <BarGraph isLoading={isBarChartLoading} data={barChartData} />
        <div className="flex flex-col lg:flex-row justify-between gap-2 mb-4">
          <div className="w-full lg:w-1/2">
            <PieChart
              isLoading={areEventsByAreaLoading}
              data={eventsDataByArea}
              name={'Events By Area'}
            />
          </div>
          <div className="w-full lg:w-1/2">
            <PieChart
              isLoading={areEventsByCameraLoading}
              data={eventsDataByCamera}
              name={'Events By Camera'}
            />
          </div>
        </div>
        <div className="mb-4">
          <Severity
            title={selectedFilters.scenario}
            isLoading={areEventsBySeverityLoading}
            data={eventsBySeverity}
            events={eventsBySeverityItems}
            areEventsLoading={areEventItemsBySeverityLoading}
            incidentLogPageNumber={incidentLogPageNumber}
            setIncidentLogPageNumber={setIncidentLogPageNumber}
            severityTypeFilter={severityTypeFilter}
            setSeverityTypeFilter={setSeverityTypeFilter}
          />
        </div>
        <div className="mb-4">
          <LineChart
            data={lineChartData}
            isLoading={isLineChartDataLoading}
            name={selectedFilters.scenario}
          />
        </div>
        {sitesAvailable &&
          sitesAvailable.length &&
          sitesAvailable
            .filter((ele) => ele.id === chosenSite.id)
            .filter((e) => e.sitemap_url !== null)
            .map((site) => {
              return (
                <Sitemap
                  selectedFilters={selectedFilters}
                  selectedDates={selectedDates}
                  site={site}
                />
              );
            })}
      </div>
    </div>
  );
};

export default Dashboard;
