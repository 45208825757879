import { AlertTriangle, Edit, Trash2 } from 'lucide-react';
import { useCallback, useState } from 'react';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { useMutation } from '@tanstack/react-query';
import { deleteCamera, updateCamera } from '../api/cameras.api';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { Button, Spinner, TextField } from '@shopify/polaris';
import { useSelector } from 'react-redux';

const EditCameraModal = ({
  camera,
  editCameraError,
  invalidFieldsError,
  handleOk,
  handleCancel,
  isPending,
  open
}) => {
  const [editCameraForm, setEditCameraForm] = useState({
    cameraName: camera?.name ?? '',
    area: camera?.area ?? '',
    description: camera?.description ?? '',
    cameraSource: camera?.rtsp ?? '',
    thumbnailUrl: camera.thumbnail_url ?? null
  });

  const validateAndSetEditCameraForm = (newCameraForm) => {
    // add character limit of 100 to cameraName, area, description
    if (
      newCameraForm.cameraName.length > 100 ||
      newCameraForm.area.length > 100 ||
      newCameraForm.description.length > 255
    ) {
      return;
    }

    setEditCameraForm(newCameraForm);
  };

  return (
    <Modal
      title="Edit Camera"
      open={open}
      maskClosable={false}
      onCancel={handleCancel}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={[
        <div className="inline mr-2" key="back">
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>,
        <div className="inline mr-2" key="submit">
          {/* <Button variant="primary" onClick={() => handleOk(camera.name, editCameraForm)}> */}
          <Button
            variant="primary"
            onClick={() => {
              handleOk({ name: camera.name, payload: editCameraForm });
            }}>
            <div className="flex items-center">
              Submit
              {isPending && (
                <span className="ml-2">
                  <Spinner size="small" />
                </span>
              )}
            </div>
          </Button>
        </div>
      ]}>
      <form>
        <div className="my-4">
          <TextField
            disabled
            value={editCameraForm.cameraName}
            onChange={(e) => validateAndSetEditCameraForm({ ...editCameraForm, cameraName: e })}
            label={
              <span>
                Camera Name <span className="text-red-500">*</span>
              </span>
            }
            placeholder="Visionify-Camera-1"
          />
        </div>
        <div className="my-4">
          <TextField
            value={editCameraForm.area}
            onChange={(e) => validateAndSetEditCameraForm({ ...editCameraForm, area: e })}
            label={
              <span>
                Area <span className="text-red-500">*</span>
              </span>
            }
            placeholder="Laundry Entrance"
          />
        </div>
        <div className="my-4">
          <TextField
            value={editCameraForm.description}
            onChange={(e) => validateAndSetEditCameraForm({ ...editCameraForm, description: e })}
            label={
              <span>
                Description <span className="text-red-500">*</span>
              </span>
            }
            placeholder="Laundry Entrance Camera"
          />
        </div>
        <div className="my-4">
          <TextField
            disabled
            value={editCameraForm.cameraSource}
            label={
              <span>
                Camera Source <span className="text-red-500">*</span>
              </span>
            }
            placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
          />
        </div>
        {/* <div className="my-4">
          <TextField
            value={editCameraForm.thumbnailUrl}
            onChange={(e) => setEditCameraForm({ ...editCameraForm, thumbnailUrl: e })}
            label={
              <span>
                Camera Thumbnail URL<span className="text-red-500">*</span>
              </span>
            }
            placeholder="https://app.visionify.ai/k8s/cameras.m3u8"
          />
        </div> */}
        {invalidFieldsError && (
          <span className="text-red-500 text-sm mt-2">* Please fill all the required fields.</span>
        )}
        {editCameraError && (
          <span className="text-red-500 text-sm mt-2">
            * Unable to add camera. Please try again later.
          </span>
        )}
      </form>
    </Modal>
  );
};

const EditCamera = (props) => {
  return (
    <div {...props}>
      <Edit size={20} color="#000" />
    </div>
  );
};

const DeleteCamera = (props) => {
  return (
    <div {...props}>
      <Trash2 size={20} color="red" />
    </div>
  );
};

const CameraHLS = ({ camera, refetch }) => {
  const {user} = useSelector((state) => state.auth);
  const [editCameraError, setEditCameraError] = useState(false);
  const [invalidFieldsError, setInvalidFieldsError] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [refetchLoader, setRefetchLoader] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const {
    mutate: initiateDelete,
    isPending: isDeletionPending,
    isError: isDeleteError
  } = useMutation({
    mutationFn: () => deleteCamera(camera.name),
    onSuccess: () => {
      setShowDeleteConfirmation(false);
      refetch();
    }
  });

  const { mutate: initiateEdit, isPending: isEditPending } = useMutation({
    mutationFn: (camera) => {
      return updateCamera(camera.name, camera.payload);
    },
    onSuccess: async () => {
      setShowEditModal(false);
      setRefetchLoader(true);
      refetch();
      setRefetchLoader(false);
    },
    onError: () => {
      setEditCameraError(true);
    }
  });

  const handleDelete = useCallback(async () => {
    initiateDelete();
  }, [camera]);

  const navigateTo = useNavigate();

  const handleEdit = useCallback(
    async (camera) => {
      const { payload } = camera;
      if (!payload.area || !payload.description) {
        setInvalidFieldsError(true);
        return;
      }
      setInvalidFieldsError(false);
      setEditCameraError(false);
      initiateEdit(camera);
    },
    [camera]
  );

  return (
    <div className="relative h-auto w-fit rounded-t-md shadow-sm border">
      {refetchLoader ? (
        <div onClick={() => navigateTo(`/cameras/${camera.id}`, { state: { camera } })}>
          <div className="h-[214px] w-[320px] bg-black rounded-t-md hover:opacity-50 cursor-pointer" />
          <div
            className="absolute top-1/2 left-1/2 transform text-white"
            style={{
              transform: 'translate(-50%, -50%)'
            }}>
            <Spinner size="large" />
          </div>
        </div>
      ) : camera?.thumbnail_url ? (
        <div
          className="h-[214px] w-[320px] hover:opacity-50 cursor-pointer"
          onClick={() => navigateTo(`/cameras/${camera.id}`, { state: { camera } })}>
          <img src={camera.thumbnail_url} alt="camera" className="w-full h-full object-cover" />
        </div>
      ) : (
        <div onClick={() => navigateTo(`/cameras/${camera.id}`, { state: { camera } })}>
          <div className="h-[214px] w-[320px] bg-black rounded-t-md hover:opacity-50 cursor-pointer" />
          <div
            className="absolute top-1/2 left-1/2 transform text-white"
            style={{
              transform: 'translate(-50%, -50%)'
            }}>
            No Preview Available
          </div>
        </div>
      )}
      <div className="flex justify-between text-[#242424] text-sm font-semibold bg-white px-2 py-2 w-full h-auto rounded-b-md shadow-md">
        <span
          className="hover:underline cursor-pointer"
          onClick={() => navigateTo(`/cameras/${camera.id}`, { state: { camera } })}>
          {camera.name.length > 20? camera.name.substring(0, 20) + '...': camera.name}
        </span>
        <div className="flex">
          <EditCamera
            className={`mr-2 cursor-pointer ${user.role_id === 6 ? 'hidden' : ''}`}
            onClick={() => {
              setShowEditModal(true);
            }}
          />
          <DeleteCamera
            className={`cursor-pointer ${user.role_id === 6 ? 'hidden' : ''}`}
            onClick={() => {
              setShowDeleteConfirmation(true);
            }}
          />
        </div>
      </div>
      <DeleteConfirmationModal
        show={showDeleteConfirmation}
        handleClose={() => {
          setShowDeleteConfirmation(false);
        }}
        handleDelete={handleDelete}
        prompt={
          <div className="flex items-center">
            <AlertTriangle size={24} color="red" />
            <span className="text-md ml-2">Are you sure you want to delete {camera.name}?</span>
          </div>
        }
        isDeletionPending={isDeletionPending}
        isDeleteError={isDeleteError}
        errorMessage="* Unable to delete camera. Please try again."
      />
      <EditCameraModal
        camera={camera}
        editCameraError={editCameraError}
        invalidFieldsError={invalidFieldsError}
        handleOk={handleEdit}
        handleCancel={() => setShowEditModal(false)}
        open={showEditModal}
        isPending={isEditPending}
      />
    </div>
  );
};
export default CameraHLS;
