import { TextField } from '@shopify/polaris';
import { getEmailRegex } from '../../utils';

const SiteForm = ({ formData, setFormData , isEmailValid, setIsEmailValid}) => {
  return (
    <div>
      <style>
        {`.Polaris-TextField {
                margin-top: 10px;
            }`}
      </style>
      <div className="w-full">
        <div className="flex items-center mb-2">
          <span className="flex-1">
            Full Name<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.name}
              onChange={(value) => {
                if (value.length > 100) return;
                setFormData({ ...formData, name: value });
              }}
              labelHidden
              placeholder="Benjamin Bookcase"
            />
          </span>
        </div>

        <div className="flex items-center mb-2">
          <span className="flex-1">
            Email<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.email}
              onChange={(value) => {
                if(value.length > 200) return;
                const regex = getEmailRegex();
                if (!regex.test(value)) {
                  setIsEmailValid(false);
                } else {
                  setIsEmailValid(true);
                }
                setFormData({ ...formData, email: value });
              }}
              labelHidden
              placeholder="bbookcase@visionify.ai"
            />
            {!isEmailValid && (
              <span className="text-red-500 text-xs mt-2">Invalid Email Address</span>
            )}
          </span>
        </div>
        <div className="flex mb-2">
          <span className="flex-1">
            Address<span className="text-red-500">*</span>
          </span>

          <span className="w-1/2 lg:w-3/4">
            <TextField
              value={formData.addressLn1}
              onChange={(value) => {
                if (value.length > 200) return;
                setFormData({ ...formData, addressLn1: value });
              }}
              labelHidden
              placeholder="123 Palo Alto St"
            />

            <TextField
              value={formData.addressLn2}
              onChange={(value) => {
                if (value.length > 200) return;
                setFormData({ ...formData, addressLn2: value });
              }}
              labelHidden
              placeholder="Unit 23"
            />
            <div className="flex justify-between">
              <span className="w-3/4">
                <TextField
                  value={formData.state}
                  onChange={(value) => {
                    if (value.length > 50) return;
                    setFormData({ ...formData, state: value });
                  }}
                  labelHidden
                  placeholder="Colorado"
                />
              </span>
              <span className="w-1/4 mx-2">
                <TextField
                  value={formData.zipcode}
                  onChange={(value) => {
                    if (value.length > 10) return;
                    if (isNaN(value)) return;
                    if (value[value.length - 1] === '.') return;
                    setFormData({ ...formData, zipcode: value });
                  }}
                  labelHidden
                  placeholder="95112"
                />
              </span>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SiteForm;
