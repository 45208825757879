import '../styles/ScenarioCard.css';
import { MediaCard } from '@shopify/polaris';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ScenarioCard = ({ scenario }) => {
  const navigateTo = useNavigate();

  const allowedScenarios = useSelector((state) => state.company.subscribedScenarios);
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="card-dimensions">
      <MediaCard
        portrait
        title={scenario.title}
        primaryAction={{
          disabled: !(allowedScenarios.includes(scenario.name)) || user.role_id === 6,
          accessibilityLabel: 'Get This',
          content: 'Get This',
          onAction: () => {
            navigateTo(`/scenarios/apply/${scenario.id}/${scenario.name}`);
          }
        }}
        secondaryAction={{
          content: 'Learn More',
          onAction: () => {
            navigateTo(`/scenarios/${scenario.name}`);
          },
          plain: true
        }}
        description={<span className="card-description">{scenario.tagline}</span>}>
        <div className="card-holder">
          <img className="card-image" alt="" src={scenario.icon} />
        </div>
      </MediaCard>
    </div>
  );
};

export default ScenarioCard;
