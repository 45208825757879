/* eslint-disable */
import React from 'react';
import { Card, Badge, Popover, ActionList } from '@shopify/polaris';
import MoreIco from '../assets/more.svg';
import viewCamera from '../assets/view-camera.svg';
import { DeleteMinor, EditMinor } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';

const CameraListCard = ({ camera }) => {
  const [popoverActive, setPopoverActive] = React.useState(false);

  const togglePopoverActive = () => setPopoverActive((prevState) => !prevState);

  const activator = (
    <img onClick={togglePopoverActive} src={MoreIco} className="w-[25px] cursor-pointer" />
  );

  const navigateTo = useNavigate()

  return (
    <Card padding="0">
      <div className="grid grid-cols-1 sm:grid-cols-12">
        <div className="sm:col-span-3">
          <div className="relative h-full">
            <img src={camera.thumbnail_url} alt="camera" className="w-full h-full object-cover" />
            {/* <div className="flex items-center gap-2 absolute top-3 left-3 bg-black bg-opacity-50 py-1.5 px-2 rounded-full">
              <span className="bg-green-500 w-2 h-2 rounded-full"></span>
              <span className="text-sm text-white">Online</span>
            </div> */}
          </div>
        </div>
        <div className="sm:col-span-9">
          <div className="bg-[#F7F4FF] py-3 px-3">
            <div className="flex items-center justify-between">
              <h2 className="font-semibold text-base">{camera.name}</h2>
              {/* <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive}>
                <ActionList
                  items={[
                    {
                      content: 'Edit',
                      icon: EditMinor,
                      onAction: () => console.log('Edit clicked')
                    },
                    {
                      content: 'Delete',
                      icon: DeleteMinor,
                      onAction: () => console.log('Delete clicked')
                    }
                  ]}
                />
              </Popover> */}
            </div>
            <p className="text-xs pt-2 text-gray-500">
              Area:<span className="text-black"> {camera.area}</span>
            </p>
          </div>
          <div className="py-3 px-3">
            <h3 className="text-base text-black font-semibold">Scenarios & Events Applied</h3>
            <div className="flex flex-wrap gap-2 mt-4">
              {camera.applied_scenarios.map((scenario) => (
                <Badge key={scenario.id} tone="info" size="large">
                  {scenario.scenario_name}
                </Badge>
              ))}
            </div>

            <div className="flex flex-wrap justify-between gap-2 mt-4">
              <div className="flex items-center flex-wrap gap-2">
                {camera.applied_scenarios
                  .reduce((acc, curr) => {
                    const events = curr.selected_events.split(',');
                    return [...acc, ...events];
                  }, [])
                  .map((e, idx) => (
                    <Badge key={idx}>{e.charAt(0).toUpperCase() + e.slice(1)}</Badge>
                  ))}
              </div>
              
              <img onClick={( )=> navigateTo(`/cameras/${camera.id}`)} src={viewCamera} className="w-[30px] cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CameraListCard;
