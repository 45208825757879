import axios from "axios";
import { store } from "../store/store";
import { logout } from "../store/slices/authSlice";
let baseUrl = new URL(window.location.href).origin;

const BASE_URL = process.env.REACT_APP_BASE_URL || baseUrl

const api = axios.create({
    baseURL: `${BASE_URL}/api`,
});


api.interceptors.request.use(
    (config) => {
        const {auth} = store.getState()
        if(auth.user) {
            if (!auth.user.company_id || !auth.user.user_id || !auth.chosenSite?.id) {
                store.dispatch(logout()); 
                return Promise.reject(new Error("Missing IDs, logging out..."));
            }
            config.headers['company-id'] = auth.user.company_id;
            config.headers['user-id'] = auth.user.user_id;
            config.headers['site-id'] = auth.chosenSite?.id ?? 1;
            config.headers['Authorization'] = `Bearer ${auth.user.access_token}`;
        }
        return Promise.resolve(config);
    },

    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return Promise.resolve(response);
    },

    (error) => {
        if (error.response && error.response.status === 401) {
            store.dispatch(logout());
        }
        return Promise.reject(error);
    }
);

export default api;