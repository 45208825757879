import api from './index';

export const getTags = async () => {
  try {
    const response = await api.get('/tags');
    if (response.data?.success) {
      const tags = response.data.data.map((option, idx) => ({
        value: { id: option.id ?? idx, name: option.title, value: option.name },
        label: option.title
      }));
      return tags;
    }

    throw new Error('Unable to fetch tags');
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};

export const createTag = async ({ name }) => {
  try {
    const response = await api.post('/tags', { tag: name });
    if (response.data?.success) {
      return true;
    }
    throw new Error('Unable to create tag');
  } catch (e) {
    throw new Error(e);
  }
};

export const getSimpleTags = async () => {
  try {
    const response = await api.get('/tags');
    if (response.data?.success) {
      const tags = response.data.data.map((option) => option.title);
      return ['All', ...tags];
    }
    throw new Error('Unable to fetch tags');
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};
