/* eslint-disable */
import { InlineError, Label, Select, TextField } from '@shopify/polaris';
import React from 'react';

const SpeakerForm = ({ speakerDetails, errors, handleInputChange }) => {
  const statusOptions = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' }
  ];

  return (
    <div className="space-y-6 pt-5 pb-5">
      <div className="block sm:flex items-center gap-5 w-full">
        <div className="w-[200px] pb-1">
          <Label>Speaker Name</Label>
        </div>
        <div className="w-full relative">
          <TextField
            type="text"
            labelHidden
            value={speakerDetails.speaker_name}
            onChange={handleInputChange('speaker_name')}
            placeholder="Enter the speaker name"
            autoComplete="off"
          />
          {errors.speaker_name && (
            <InlineError message={errors.speaker_name} fieldID="speaker_name" />
          )}
        </div>
      </div>

      <div className="block sm:flex items-center gap-5 w-full">
        <div className="w-[200px] pb-1">
          <Label>IP Address</Label>
        </div>
        <div className="w-full relative">
          <TextField
            type="text"
            labelHidden
            value={speakerDetails.ip_address}
            onChange={handleInputChange('ip_address')}
            placeholder="Enter IP address (e.g., 192.168.0.1:8080)"
            autoComplete="off"
          />
          {errors.ip_address && <InlineError message={errors.ip_address} fieldID="ip_address" />}
        </div>
      </div>
      <div className="block sm:flex items-center gap-5 w-full">
        <div className="w-[200px] pb-1">
          <Label>Status</Label>
        </div>
        <div className="w-full relative">
          <Select
            options={statusOptions}
            onChange={handleInputChange('status')}
            value={speakerDetails.status || 'ACTIVE'}
          />
          {errors.status && <InlineError message={errors.status} fieldID="status" />}
        </div>
      </div>
    </div>
  );
};

export default SpeakerForm;
