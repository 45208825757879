import { formatString } from '../utils';
import api from './index';

export const downloadReport = async (payload) => {
  try {
    const response = await api.post('/reports/generate-report', payload, {
      responseType: 'blob'
    });

    const timePeriodLabel = formatString(payload.timePeriod)

    const cdHeader = response.headers['content-disposition'];
    const filename = cdHeader
      ? cdHeader.split('filename=')[1].replaceAll('"', '')
      : `Reports - ${payload.scenarioType} - ${timePeriodLabel}.xlsx`;

    // Create a URL for the blob
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    return { success: true, message: 'Report downloaded successfully' };
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
};
