import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allScenarios: [],
  scenarios: [],
  chosenScenario: null,
  scenarioDetails: null,
  notificationGroups: [],
  scenarioFormData: {
    cameras: [],
    events: {},
    selectedNotificationGroups: [],
    polygonPointsPerCamera: {},
    minWorkerCount: 1,
    maxWorkerCount: 1,
    eventLevel: ['ALL'],
    startTime: '00:00',
    endTime: '23:59',
    modelVersion: '0.0.1',
    modelConfidence: 80,
    detectionDuration: 12,
    hysteresisTime: 5,
    imageDimensionsPerCamera: {},
    actualImageDimensionsPerCamera: {},
    cameraName: ''
  }
};

export const scenarioSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    setNotificationGroups: (state, action) => {
      state.notificationGroups = action.payload;
    },
    resetNotificationGroups: (state) => {
      state.notificationGroups = initialState.notificationGroups;
    },
    setScenarios: (state, action) => {
      state.scenarios = action.payload;
    },
    setScenarioFormData: (state, action) => {
      state.scenarioFormData = action.payload;
    },
    resetScenarioFormData: (state) => {
      state.scenarioFormData = initialState.scenarioFormData;
    },
    setChosenScenario: (state, action) => {
      state.chosenScenario = action.payload;
    },
    resetChosenScenario: (state) => {
      state.chosenScenario = initialState.chosenScenario;
    },
    setScenarioDetails: (state, action) => {
      state.scenarioDetails = action.payload;
    },
    resetScenarioDetails: (state) => {
      state.scenarioDetails = initialState.scenarioDetails;
    },
    setAllScenarios: (state, action) => {
      state.allScenarios = action.payload;
    },
    resetAllScenarios: (state) => {
      state.allScenarios = initialState.allScenarios;
    }
  }
});

export const {
  setScenarios,
  setScenarioFormData,
  resetScenarioFormData,
  setChosenScenario,
  resetChosenScenario,
  setNotificationGroups,
  resetNotificationGroups,
  setScenarioDetails,
  resetScenarioDetails,
  setAllScenarios,
  resetAllScenarios
} = scenarioSlice.actions;

export default scenarioSlice.reducer;
