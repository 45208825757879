import { Icon, LegacyCard, Tabs, Tooltip } from '@shopify/polaris';

import { useCallback, useState } from 'react';

import CompanyInfo from './CompanyInfo';
import SiteInfo from './SiteInfo';
import { PlusMinor, QuestionMarkMajor } from '@shopify/polaris-icons';
import CreateSiteModal from './CreateSiteModal';
import EditSiteModal from './EditSiteModal';

import { useQuery } from '@tanstack/react-query';
import { getSites } from '../../api/sites.api';

import MsTeamsWebhookInfo from './MsTeamsWebhookInfo';
import MsTeamsWebhookModal from './MsTeamsWebhookModal';
import { getMSTeamsWebhooks } from '../../api/msteamswebhooks.api';

const renderTabContent = (
  { selectedIdx },
  { setShowSiteEditModal, siteCallback, setChosenSiteData },
  { setShowTeamsWebhookModal, msTeamsWebhookCallback, setChosenTeamsWebhookData }
) => {
  switch (selectedIdx) {
    case 0:
      return <CompanyInfo />;

    case 1:
      return (
        <SiteInfo
          setShowSiteEditModal={setShowSiteEditModal}
          siteCallback={siteCallback}
          setChosenSiteData={setChosenSiteData}
        />
      );

    case 2:
      return (
        <MsTeamsWebhookInfo
          setShowTeamsWebhookModal={setShowTeamsWebhookModal}
          msTeamsWebhookCallback={msTeamsWebhookCallback}
          setChosenTeamsWebhookData={setChosenTeamsWebhookData}
        />
      );

    default:
      return null;
  }
};

const CompanySettings = () => {
  const [selected, setSelected] = useState(0);
  const [showSiteCreationModal, setShowSiteCreationModal] = useState(false);
  const [showSiteEditModal, setShowSiteEditModal] = useState(false);
  const [chosenSiteData, setChosenSiteData] = useState(null);
  const [showTeamsWebhookModal, setShowTeamsWebhookModal] = useState(false);
  const [chosenTeamsWebhookData, setChosenTeamsWebhookData] = useState(null);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  const siteCallback = useQuery({
    queryKey: ['siteInfo'],

    queryFn: getSites
  });

  const msTeamsWebhookCallback = useQuery({
    queryKey: ['msteamswebhooks'],
    queryFn: getMSTeamsWebhooks
  });

  const tabs = [
    {
      id: 'company-info',
      content: 'Company',
      accessibilityLabel: 'Company Info',
      panelID: 'company-info'
    },
    {
      id: 'site-info',
      content: 'Sites',
      accessibilityLabel: 'Sites Info',
      panelID: 'site-info'
    },
    {
      id: 'ms-teams-info',
      content: 'Microsoft Teams',
      accessibilityLabel: 'Microsoft Teams',
      panelID: 'ms-teams-info'
    }
  ];

  const renderActions = (selected) => {
    switch (selected) {
      case 1:
        return [
          {
            content: 'Add New Site',
            icon: <Icon source={PlusMinor} />,
            onAction: () => setShowSiteCreationModal(true)
          }
        ];
      case 2:
        return [
          {
            content: 'Add New Webhook',
            icon: <Icon source={PlusMinor} />,
            onAction: () => {
              setShowTeamsWebhookModal(true);
              setChosenTeamsWebhookData(null);
            }
          },
          {
            content: (
              <Tooltip content="Create Teams webhook">
                <div
                  onClick={() =>
                    window.open(
                      'https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook?tabs=newteams%2Cdotnet#create-an-incoming-webhook',
                      '_blank'
                    )
                  }
                  style={{ cursor: 'pointer' }}>
                  <Icon source={QuestionMarkMajor} color="inkLighter" />
                </div>
              </Tooltip>
            )
          }
        ];
      default:
        return [];
    }
  };

  return (
    <div className="flex flex-col mx-4 my-4">
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        <LegacyCard.Section title={tabs[selected].content} actions={renderActions(selected)}>
          {renderTabContent(
            { selectedIdx: selected },
            { setShowSiteEditModal, siteCallback, setChosenSiteData },
            { setShowTeamsWebhookModal, msTeamsWebhookCallback, setChosenTeamsWebhookData }
          )}
        </LegacyCard.Section>
      </Tabs>

      <CreateSiteModal
        isOpen={showSiteCreationModal}
        setIsOpen={setShowSiteCreationModal}
        handleCancel={() => setShowSiteCreationModal(false)}
        siteCallback={siteCallback}
      />

      <EditSiteModal
        isOpen={showSiteEditModal}
        chosenSiteData={chosenSiteData}
        setIsOpen={setShowSiteEditModal}
        siteCallback={siteCallback}
        handleCancel={() => {
          setShowSiteEditModal(false);
          setChosenSiteData(null);
        }}
      />

      <MsTeamsWebhookModal
        isOpen={showTeamsWebhookModal}
        chosenTeamsWebhookData={chosenTeamsWebhookData}
        setChosenTeamsWebhookData={setChosenTeamsWebhookData}
        setIsOpen={setShowTeamsWebhookModal}
        msTeamsWebhookCallback={msTeamsWebhookCallback}
        handleCancel={() => {
          setShowTeamsWebhookModal(false);
          setChosenTeamsWebhookData(null);
        }}
      />
    </div>
  );
};

export default CompanySettings;
