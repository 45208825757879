import api from './index';
const resource = '/msteamswebhooks';

export const getMSTeamsWebhooks = async () => {
  try {
    const response = await api.get(`${resource}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getMSTeamsWebhookById = async (id) => {
  try {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const createMSTeamsWebhook = async (data) => {
  try {
    const response = await api.post(`${resource}`, data);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};

export const updateMSTeamsWebhook = async ({ id, data }) => {
  try {
    const response = await api.put(`${resource}/${id}`, data);
    return response.data;
  } catch (error) {
    const errorMessage = error.response?.data?.message || error.message;
    const errorStatus = error.response?.status;
    throw { message: errorMessage, status: errorStatus };
  }
};
