import momentTz from 'moment-timezone';
import { getCountryPhoneCodeMap } from './data/timezones';
// import moment from 'moment';
function groupCamerasByCount(cameraArray) {
  // Object to hold the count of each camera
  const cameraCount = {};
  const cameraCountByLocation = {};

  // Iterate over the array to build the count
  cameraArray.forEach((item) => {
    if (cameraCount[item.camera_name]) {
      cameraCount[item.camera_name]++;
    } else {
      cameraCount[item.camera_name] = 1;
    }

    if (!item.severity) {
      if (cameraCountByLocation['NULL']) cameraCountByLocation['NULL']++;
      else cameraCountByLocation['NULL'] = 1;
    }
    if (item.severity && cameraCountByLocation[item.severity]) {
      cameraCountByLocation[item.severity]++;
    } else {
      cameraCountByLocation[item.severity] = 1;
    }
  });

  // Convert the count into the desired output format
  const groupedCamerasByName = Object.keys(cameraCount).map((key) => {
    return { group: key, value: cameraCount[key] };
  });

  const groupedCamerasByArea = Object.keys(cameraCountByLocation).map((key) => {
    return { group: key, value: cameraCountByLocation[key] };
  });

  return [groupedCamerasByName, groupedCamerasByArea];
}

function countEventsPerDay(events, previousEvents, currLabel, prevLabel) {
  const counts = {};
  const prevCounts = {};

  events.forEach((event) => {
    // Extract just the date part from the 'created_at' field
    const date = event.created_at.split('T')[0];

    // Increment the count for this date
    if (!counts[date]) {
      counts[date] = 1;
    } else {
      counts[date]++;
    }
  });

  previousEvents.forEach((event) => {
    // Extract just the date part from the 'created_at' field
    const date = event.created_at.split('T')[0];

    // Increment the count for this date
    if (!prevCounts[date]) {
      prevCounts[date] = 1;
    } else {
      prevCounts[date]++;
    }
  });

  // Convert the counts into the desired array format
  const currentWeek = Object.entries(counts).map(([key, value]) => {
    return {
      group: currLabel,
      key: key,
      value: value
    };
  });

  const previousWeek = Object.entries(prevCounts).map(([key, value]) => {
    return {
      group: prevLabel,
      key: key,
      value: value
    };
  });

  return [...currentWeek, ...previousWeek] || [];
}

function convertToTimeSeries(data) {
  // Create a map to count occurrences for each datetime
  const datetimeCounts = new Map();

  data.forEach((item) => {
    const datetime = item.created_at;
    datetimeCounts.set(datetime, (datetimeCounts.get(datetime) || 0) + 1);
  });

  // Convert the map to the desired array format
  const timeSeriesData = Array.from(datetimeCounts).map(([datetime, count]) => {
    return {
      group: 'Incident Occurrences',
      date: datetime,
      value: count,
      surplus: calculateSurplus(count) // Replace this with actual logic if needed
    };
  });

  return timeSeriesData;
}

// Dummy function for surplus calculation - replace with actual logic
function calculateSurplus() {
  return Math.random() * 100000; // Example calculation
}

function timeSince(date) {
  if (!date) {
    return '0:0:0:0';
  }
  const now = new Date();
  const past = new Date(date);
  const diff = now - past;

  let seconds = Math.floor(diff / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  let days = Math.floor(hours / 24);

  hours = hours % 24;
  minutes = minutes % 60;
  seconds = seconds % 60;

  return [
    days.toString().padStart(2, '0'),
    hours.toString().padStart(2, '0'),
    minutes.toString().padStart(2, '0'),
    seconds.toString().padStart(2, '0')
  ].join(':');
}

const convertToScenarioOptions = (scenarios) => {
  const options = scenarios
    .filter((scenario) => scenario.completed === true)
    .map((scene) => ({ id: scene.name, text: scene.title }));

  return options;
};

const convertToEventOptions = (scenarios) => {
  const events = {};
  scenarios
    .filter((scenario) => scenario.completed === true)
    .map((scene) => {
      Object.keys(scene.events).map((key) => {
        if (!events[scene.name]) {
          events[scene.name] = [{ label: key, value: key }];
        } else {
          events[scene.name].push({ label: key, value: key });
        }
        return null;
      });
      return null;
    });

  return events;
};

function sortEventCount(eventCount) {
  // Create an array from the object, sort it, and map to the desired format
  return Object.keys(eventCount)
    .sort((a, b) => b.localeCompare(a)) // Sort keys in descending order
    .map((key) => ({ name: key, count: eventCount[key] })); // Map to the required format
}

function dateToWords(dateInput, noTime = false) {
  if (!dateInput) return '';
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
  ];

  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput;

  const year = date.getFullYear();
  const month = months[date.getMonth()];
  const day = date.getDate();
  let hour = date.getHours();
  const minute = date.getMinutes();

  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${ampm}`;

  if (noTime) return `${month} ${ordinalSuffixOf(day)}, ${year}`;

  return `${month} ${ordinalSuffixOf(day)}, ${year} - ${formattedTime}`;
}

export function dateToWordsTz(dateInput, timezone = 'UTC', noTime = false) {
  if (!dateInput) return '';
  let tz = null;
  if (!timezone) tz = 'UTC';
  else tz = timezone;

  // Define options for formatting the date
  const options = {
    timeZone: tz,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  };

  // Create a formatter with the specified or default timezone
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(new Date(dateInput));

  // Initialize components
  let month, day, year, hour, minute, ampm;

  // Extract components from the formatted parts
  parts.forEach((part) => {
    if (part.type === 'month') month = part.value;
    if (part.type === 'day') day = part.value; // Extract day as-is, without ordinal suffix yet
    if (part.type === 'year') year = part.value;
    if (part.type === 'hour') hour = part.value;
    if (part.type === 'minute') minute = part.value;
    if (part.type === 'dayPeriod') ampm = part.value;
  });

  // Append ordinal suffix to day
  const dayWithSuffix = day + ordinalSuffixOf(parseInt(day)); // Ensure we're not modifying 'day' but creating a new value

  const formattedTime = `${hour}:${minute} ${ampm}`;

  if (noTime) return `${month} ${dayWithSuffix}, ${year}`;

  return `${month} ${dayWithSuffix}, ${year} - ${formattedTime}`;
}

function ordinalSuffixOf(i) {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return 'st';
  }
  if (j === 2 && k !== 12) {
    return 'nd';
  }
  if (j === 3 && k !== 13) {
    return 'rd';
  }
  return 'th';
}
function formatDate(date) {
  date = new Date(date);
  let day = date.getDate(); // Get the day as a number (1-31)
  let month = date.getMonth() + 1; // Get the month as a number (0-11)
  let year = date.getFullYear(); // Get the four digit year

  // Add leading zeros to month and day if they are less than 10
  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' + month;

  // Concatenate the strings in mm/dd/yyyy format
  return month + '/' + day + '/' + year;
}

function convertKeyToTitle(key, separator = '-') {
  return key
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

function countKeyOccurrences(array, key) {
  let countObj = {};
  let totalCount = 0;
  array.forEach((obj) => {
    if (obj[key] in countObj) {
      countObj[obj[key]]++;
    } else {
      countObj[obj[key]] = 1;
    }
  });
  Object.keys(countObj).forEach((k) => {
    totalCount += countObj[k];
  });
  return [
    Object.keys(countObj).map((ele) => {
      return { name: ele, data: [{ key: ele, value: countObj[ele] }] };
    }),
    totalCount
  ];
}

function groupEventsBy(array, timeUnit, current = true, tz = 'UTC') {
  let timezone = tz;
  if (!timezone) timezone = 'UTC';
  const counts = {};
  const timeFormatOptions = {
    HOURS: { unit: 'hour', format: 'HH:mm' },
    DAYS: { unit: 'day', format: 'YYYY-MM-DD' },
    WEEKS: { unit: 'day', format: 'YYYY-MM-DD' },
    // WEEKS: { unit: "week", format: "YYYY-[W]WW" },
    MONTHS: { unit: 'month', format: 'YYYY-MM' }
  };

  const { unit, format } = timeFormatOptions[timeUnit];

  array.forEach((item) => {
    if (item.created_at) {
      const date = momentTz.tz(item.created_at, timezone).startOf(unit).format(format);
      counts[date] = (counts[date] || 0) + 1;
    }
  });

  const data = Object.entries(counts).map(([key, value]) => ({ key, value }));
  if (timeUnit === 'HOURS' && !current) {
    return {
      name: 'Previous Day',
      data
    };
  } else if (timeUnit === 'HOURS' && current) {
    return {
      name: 'Current Day',
      data
    };
  }
  if (timeUnit === 'DAYS' && !current) {
    return {
      name: 'Previous Day',
      data
    };
  } else if (timeUnit === 'DAYS' && current) {
    return {
      name: 'Current Day',
      data
    };
  }

  if (timeUnit === 'WEEKS' && !current) {
    return {
      name: 'Previous Month',
      data
    };
  } else if (timeUnit === 'WEEKS' && current) {
    return {
      name: 'Current Month',
      data
    };
  }

  if (timeUnit === 'MONTHS' && !current) {
    return { name: 'Previous Year', data };
  } else if (timeUnit === 'MONTHS' && current) {
    return { name: 'Current Year', data };
  }

  return {
    name: `Current ${unit.charAt(0).toUpperCase() + unit.slice(1)}`,
    data: data
  };
}

function formatEventDatesAndTimes(events, tz = 'UTC') {
  return events.map((event) => {
    const dateTime = momentTz.tz(event.created_at, tz);
    const date = dateTime.format('YYYY-MM-DD'); // Gets the date in the specified timezone
    const time = dateTime.format('HH:mm'); // Gets the time in the specified timezone

    return {
      cameraName: event.camera_name,
      area: event.area,
      date: date,
      time: time
    };
  });
}

const sortDataByDate = (data) => {
  return data.sort((a, b) => new Date(a.key) - new Date(b.key));
};

const getAvaragePoint = (points) => {
  let totalX = 0;
  let totalY = 0;
  for (let i = 0; i < points.length; i += 2) {
    totalX += points[i];
    totalY += points[i + 1];
  }
  return {
    x: totalX / (points.length / 2),
    y: totalY / (points.length / 2)
  };
};
const getDistance = (node1, node2) => {
  let diffX = Math.abs(node1[0] - node2[0]);
  let diffY = Math.abs(node1[1] - node2[1]);
  const distaneInPixel = Math.sqrt(diffX * diffX + diffY * diffY);
  return Number.parseFloat(distaneInPixel).toFixed(2);
};
const dragBoundFunc = (stageWidth, stageHeight, vertexRadius, pos) => {
  let x = pos.x;
  let y = pos.y;
  if (pos.x + vertexRadius > stageWidth) x = stageWidth;
  if (pos.x - vertexRadius < 0) x = 0;
  if (pos.y + vertexRadius > stageHeight) y = stageHeight;
  if (pos.y - vertexRadius < 0) y = 0;
  return { x, y };
};
const minMax = (points) => {
  return points.reduce((acc, val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);
};

function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
}

function getValidIpAddress(url) {
  if (!url) return 'N/A';
  // Regular expression to match RTSP, HTTP, or HTTPS URLs and extract the server part
  const protocolRegex = /(rtsp|http|https):\/\/(.*?)(?=\/|$)/i;

  // Regular expression to validate an IPv4 address
  const ipAddressRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;

  // Attempt to extract the server part from the URL
  const match = url.match(protocolRegex);
  if (!match || match.length < 3) {
    return 'N/A'; // Return "N/A" if no server part can be extracted
  }

  // Extract the server address
  const serverAddress = match[2];

  // Attempt to extract an IP address from the server address
  const ipMatch = serverAddress.match(ipAddressRegex);
  if (ipMatch) {
    // Validate the extracted IP address
    const ipAddress = ipMatch[0];
    // Split IP address into octets to check each numeric value
    const octets = ipAddress.split('.').map(Number);
    // Ensure each octet is within the valid range 0-255
    const isValid = octets.every((octet) => octet >= 0 && octet <= 255);
    if (isValid) {
      return ipAddress; // Return the valid IP address
    }
  }

  return 'N/A'; // Return "N/A" if the IP address is not valid
}

function isProtocolValid(url) {
  const pattern = /^(http|https|rtsp|rtmp):\/\/[^\s$.?#].[^\s]*$/;
  return pattern.test(url);
}

// removes _ and capitalizes first letter of each word
function formatString(s) {
  // Replace all underscores with spaces
  s = s.replace(/_/g, ' ');
  // Split the string into words
  let words = s.split(' ');
  // Process each word
  let formattedWords = words.map((word) => {
    if (!isNaN(word[0])) {
      // Check if the first character is a number
      return word;
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });
  // Join the processed words back into a string
  return formattedWords.join(' ');
}

const generateRandomPassword = (length = 12) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_-';
  let password = '';
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

const convertOptionsToConsumableFormat = (options) => {
  return options.map((option, idx) => ({
    value: { id: option.id ?? idx, name: option.name },
    label: option.name
  }));
};

const constructPhoneNumberWithTimezone = (timezone, phone) => {
  const countryCodes = getCountryPhoneCodeMap();
  const tz = countryCodes[timezone];
  return `${tz}${phone}`;
};

export const formatTime = (datetimeString, timezone = 'UTC', format = 'hh:mm A') => {
  if (!timezone) timezone = 'UTC';
  return momentTz(datetimeString).tz(timezone).format(format);
};

export const getEmailRegex = () => /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export function getCameraIconDataUrl(width = 24, height = 24) {
  const svgString = `
<svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" width="${width}" height="${height}" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-cctv">
  <path d="M16.75 12h3.632a1 1 0 0 1 .894 1.447l-2.034 4.069a1 1 0 0 1-1.708.134l-2.124-2.97"/>
  <path d="M17.106 9.053a1 1 0 0 1 .447 1.341l-3.106 6.211a1 1 0 0 1-1.342.447L3.61 12.3a2.92 2.92 0 0 1-1.3-3.91L3.69 5.6a2.92 2.92 0 0 1 3.92-1.3z"/>
  <path d="M2 19h3.76a2 2 0 0 0 1.8-1.1L9 15"/>
  <path d="M2 21v-4"/>
  <path d="M7 9h.01"/>
</svg>`;

  return `data:image/svg+xml;base64,${btoa(svgString)}`;
}

export function getActiveCameraIconDataUrl(width = 24, height = 24) {
  const svgString = `
<svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer" width="${width}" height="${height}" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-cctv">
  <path d="M16.75 12h3.632a1 1 0 0 1 .894 1.447l-2.034 4.069a1 1 0 0 1-1.708.134l-2.124-2.97"/>
  <path d="M17.106 9.053a1 1 0 0 1 .447 1.341l-3.106 6.211a1 1 0 0 1-1.342.447L3.61 12.3a2.92 2.92 0 0 1-1.3-3.91L3.69 5.6a2.92 2.92 0 0 1 3.92-1.3z"/>
  <path d="M2 19h3.76a2 2 0 0 0 1.8-1.1L9 15"/>
  <path d="M2 21v-4"/>
  <path d="M7 9h.01"/>
</svg>`;

  return `data:image/svg+xml;base64,${btoa(svgString)}`;
}

export {
  constructPhoneNumberWithTimezone,
  generateRandomPassword,
  convertOptionsToConsumableFormat,
  formatString,
  isProtocolValid,
  getValidIpAddress,
  deepEqual,
  getAvaragePoint,
  getDistance,
  dragBoundFunc,
  minMax,
  formatEventDatesAndTimes,
  groupEventsBy,
  countKeyOccurrences,
  sortEventCount,
  groupCamerasByCount,
  countEventsPerDay,
  convertToTimeSeries,
  timeSince,
  convertToScenarioOptions,
  dateToWords,
  formatDate,
  convertKeyToTitle,
  debounce,
  convertToEventOptions,
  sortDataByDate
};
